import React from "react";
import { mapping } from "../style/mapping";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { SmallAvatar } from "./Avatar";
import { NameCard } from "./NameCard";
import { filterBy } from "@progress/kendo-data-query";

class MultiSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value.map(user => user.display_name),
            IDarray: this.props.value.map(user => user.username),
            userList: this.props.userList,
            filter: ""
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            JSON.stringify(nextProps.userList) !==
            JSON.stringify(this.props.userList)
        ) {
            this.setState({ userList: nextProps.userList });
        }

        if (
            nextProps.populate_id !== this.props.populate_id ||
            JSON.stringify(nextProps.value) !== JSON.stringify(this.props.value)
        ) {
            this.setState({
                value: nextProps.value.map(user => user.display_name),
                IDarray: nextProps.value.map(user => user.username)
            });
            if (this.props.onChange) {
                this.props.onChange({
                    value: nextProps.value.map(user => user.id)
                });
            }
        }
    }

    render() {
        //console.log(this.props.userList);

        const clickEvent = event => {
            let value = event.display_name;
            let ID = event.username;

            let array = [...this.state.value]; // make a separate copy of the array

            let index = array.indexOf(value);
            if (index !== -1) {
                array.splice(index, 1);
            } else {
                array = [...this.state.value, value];
            }

            let IDarray = [...this.state.IDarray];
            let IDIndex = IDarray.indexOf(ID);
            if (IDIndex !== -1) {
                IDarray.splice(IDIndex, 1);
            } else {
                IDarray = [...this.state.IDarray, ID];
            }

            this.setState({
                value: array,
                IDarray: IDarray,
                filter: "",
                userList: this.props.userList
            });
            if (this.props.CustomOnChange) {
                this.props.CustomOnChange({ value: IDarray });
            }
            if (this.props.onChange) {
                this.props.onChange({ value: IDarray });
            }
        };

        const onFilterChange = event => {
            let filterNameList = filterBy(
                this.props.userList.map(a => a.display_name),
                event.filter
            );
            let filterDataList = this.props.userList.filter(data => {
                return filterNameList.includes(data.display_name);
            });
            this.setState({
                filter: event.filter.value,
                userList: filterDataList
            });
        };

        const onChange = event => {};

        const popupSetting = {
            className: "popupCustomization"
        };

        const blue = `.k-multiselect-wrap {
            background-color:${mapping["Color_Field_BG_Lighter"]} !important;
            border-radius:0 !important;
            box-shadow:none !important;
            color:white !important
        }
        .popupCustomization {
            background-color:${mapping["Color_DropDownButton_BG_Lighter_Disable"]} !important;
        }`;

        const popupitemRender = (li, itemProps) => {
            const index = itemProps.index;
            const itemChildren = (
                <span style={{ color: "#00F", backgroundColor: "#000" }}>
                    {li.props.children} {index}
                </span>
            );

            return React.cloneElement(li, li.props, itemChildren);
        };

        const ItemRender = props => {
            // console.log("selected ? : " + props.selected);
            const itemChildren = (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                    style={{
                        width: "100%",
                        display: "inline-flex",
                        paddingTop: "0.6rem",
                        paddingBottom: "0.6rem",
                        paddingLeft: "1rem",
                        position: "relative",
                        backgroundColor:
                            mapping["Color_DropDownButton_BG_Lighter"]
                    }}
                >
                    <NameCard
                        key={props.data.display_name + props.data.title}
                        initial={props.data.display_name
                            .split(" ")
                            .map(a => {
                                if (a[0]) {
                                    return a[0].toUpperCase();
                                }
                            })
                            .join("")}
                        name={props.data.display_name}
                        title={props.data.title}
                    />
                    <span
                        style={{
                            color: props.selected ? "green" : "grey",
                            fontSize: "2.5rem",
                            position: "absolute",
                            right: "1rem"
                        }}
                        class="k-icon k-i-plus-outline"
                    />

                    <div
                        style={{
                            backgroundColor: "red",
                            position: "absolute",
                            right: "1rem",
                            width: "10px"
                        }}
                    />
                </div>
            );
            return itemChildren;
        };

        const tagRender = (tagData, li) =>
            React.cloneElement(li, li.props, [
                <span className="custom-tag"> {tagData.data} </span>
            ]);

        return (
            <div className="example-wrapper">
                <div>
                    {this.props.title && (
                        <div
                            style={Object.assign(
                                {},
                                mapping["forms/label/1-default"],
                                { color: "white" }
                            )}
                        >
                            {this.props.title}
                        </div>
                    )}
                    <MultiSelect
                        style={{ width: "100%" }}
                        data={this.state.userList.map(a => a.display_name)}
                        filterable={true}
                        filter={this.state.filter}
                        onFilterChange={onFilterChange}
                        value={this.state.value}
                        itemRender={popupitemRender}
                        onChange={onChange}
                        tagRender={tagRender}
                        opened={false}
                        popupSettings={popupSetting}
                    />
                    <ul className={"scrollbar"} style={ListStyle}>
                        {this.state.userList.map(data => {
                            // console.log(data);
                            return (
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                <li
                                    key={data.id}
                                    onClick={() => {
                                        clickEvent(data);
                                        //console.log("clicked " + data.display_name);
                                    }}
                                >
                                    <ItemRender
                                        data={data}
                                        clickEvent={clickEvent}
                                        selected={this.state.IDarray.includes(
                                            data.username
                                        )}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <style>{blue}</style>
            </div>
        );
    }
}

const ListStyle = {
    paddingLeft: 0,
    height: "220px",
    overflowY: "scroll"
};

export { MultiSelector };
