import React from "react";
import { mapping } from "../style/mapping";
//
// need following props:
// risk: {riskNumber:'', riskName:'', riskRating:'', owner:[''], subRisk:[{},{},{}]}

const Pill = props => {
    var color = props.color;
    var content = props.content;

    var pillStyle = {
        height: "1.25rem",
        width: "fit-content",
        backgroundColor: color,
        borderRadius: "0.625rem",
        ...props.pillStyle
    };

    var contentStyle = {
        paddingLeft: "0.4375rem",
        paddingRight: "0.4375rem",
        lineHeight: "1.25rem",
        color: "#FFFFFFD0",
        ...props.contentStyle
    };

    return (
        <div style={pillStyle}>
            <div
                style={Object.assign(
                    {},
                    mapping["fonts/pill/7-light"],
                    contentStyle
                )}
            >
                {content}
            </div>
        </div>
    );
};

class RiskTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            riskID: this.props.risk.id,
            riskNumber: this.props.risk.riskNumber,
            riskName: this.props.risk.name,
            riskRating: this.props.risk,
            owner: this.props.risk.owner,
            subRiskNumber: this.props.risk.subRisk.length,
            hover: false,
            isParentRisk: this.props.risk.isParentRisk
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            riskID: nextProps.risk.id,
            riskNumber: nextProps.risk.riskNumber,
            riskName: nextProps.risk.name,
            riskRating: nextProps.risk,
            owner: nextProps.risk.owner,
            subRiskNumber: nextProps.risk.subRisk.length,
            isParentRisk: nextProps.risk.isParentRisk
        };
    }
    render() {
        var ratingColor = "";
        // console.log(this.state.riskRating.residual.rating)
        switch (this.state.riskRating.residual.rating) {
            case 1:
                ratingColor = mapping["Color_Platform_RiskLow"];
                break;
            case 2:
                ratingColor = "#5293e3";
                break;
            case 3:
                ratingColor = mapping["Color_Platform_RiskMed"];
                break;
            case 4:
                ratingColor = mapping["Color_Platform_RiskHigh"];
                break;

            default:
                ratingColor = mapping["Color_Platform_RiskLow"];
                break;
        }

        var RiskTileStyle = {
            backgroundColor: mapping["Color_Risk_Tile_Background"],
            height: "100%",
            width: this.props.width ?? "100%",
            display: "flex",
            position: "relative"
        };

        const indicatorVerticalBarStyle = {
            width: "0.25rem",
            position: "absolute",
            height: "calc(100% - 0.875rem)",
            marginTop: "0.4375",
            backgroundColor: ratingColor,
            display: "inline-flex"
        };

        const riskInformationAreaStyle = {
            marginLeft: "1rem"
        };

        const riskTileRiskNumberStyle = {
            color: ratingColor,
            marginTop: "5px",
            marginBottom: "5px"
        };

        const riskTileSubRiskNumberStyle = {
            display: "inline-flex",
            position: "absolute",
            right: "7px",
            top: "5px"
        };

        const riskTileOwnerStyle = {
            color: ratingColor,
            marginTop: "10px",
            marginBottom: "0px"
        };

        const riskTileEndingStyle = {
            marginBottom: "5px"
        };

        const riskTileHoverEffect = {
            backgroundColor: mapping["Color_Risk_Tile_Hover_Background"],
            boxShadow: "0 0 0 1px " + mapping["Color_Risk_Tile_Hover_Border"]
        };

        const onHover = () => {
            this.props.onHoverCallback([this.props.risk]);
            this.setState({ hover: true });
        };

        const onUnhover = () => {
            this.props.onUnhoverCallback();
            this.setState({ hover: false });
        };

        const onClick = () => {
            this.props.RiskTileClick(this.state.riskID);
        };

        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                data-test={"risk_tile"}
                style={
                    this.state.hover
                        ? Object.assign({}, RiskTileStyle, riskTileHoverEffect)
                        : RiskTileStyle
                }
                onMouseEnter={onHover}
                onMouseLeave={onUnhover}
                onClick={onClick}
            >
                {/* indicator vertical bar */}
                <div style={indicatorVerticalBarStyle} data-test={"risk_tile_rating"}/>
                {/* information area */}
                <div style={riskInformationAreaStyle}>
                    {/* Risk number */}
                    <div style={{ display: "flex" }}>
                        <div
                            style={Object.assign(
                                {},
                                mapping["paragraph/default/lightleft"],
                                riskTileRiskNumberStyle
                            )}
                        >
                            {this.state.riskNumber}
                        </div>
                        <div style={riskTileSubRiskNumberStyle}>
                            {this.state.isParentRisk ? (
                                <Pill
                                    color={ratingColor}
                                    content={
                                        " +" + this.state.subRiskNumber + " "
                                    }
                                />
                            ) : (
                                <Pill
                                    color={ratingColor}
                                    content={"Sub Risk"}
                                />
                            )}
                        </div>
                    </div>

                    {/* Risk name */}
                    <div
                        data-test={"risk_tile_name"} style={mapping["paragraph/default/lightleft"]}>
                        {this.state.riskName}
                    </div>

                    {/* Risk Owner*/}
                    <div
                        style={Object.assign(
                            {},
                            mapping["paragraph/default/lightleft"],
                            riskTileOwnerStyle
                        )}
                    >
                        RISK OWNER
                    </div>

                    {/* Risk Owner Name*/}
                    {this.state.owner.map(ownerName => {
                        return (
                            <div style={mapping["paragraph/small/lightleft"]}>
                                {ownerName}
                            </div>
                        );
                    })}
                    <div style={riskTileEndingStyle} />
                </div>
            </div>
        );
    }
}

export { RiskTile, Pill };
