import { mapping } from "./mapping";

var navbarStyle = {
    backgroundStyle: {
        backgroundColor: mapping["Color_BG_01_solid"],
        height: "3.75rem",
        width: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center"
    },

    headerFont:{
        ...mapping["heading/h6/lightcenter"],
        paddingLeft: "3rem",
    }
};
export { navbarStyle };
