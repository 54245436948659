import { mapping } from "./mapping";

var loginStyle = {
    formBgStyle: {
        marginTop: "9.375rem",
        height: "fit-content",
        width: "28.75rem",
        backgroundColor: mapping["ComponentBGColor"]
    },
    tileFieldPadding: {
        paddingTop: "2.5625rem"
    },
    fieldsPadding: {
        paddingTop: "0.625rem"
    },
    fieldButtonPadding: {
        paddingTop: "1.25rem"
    },
    formPadding: {
        paddingTop: "5rem",
        paddingLeft: "4.6875rem"
    },
    backgroundImage: {
        height: "100vh",
        width: "100vw",
        backgroundPosition: "center" /* Center the image */,
        backgroundRepeat: "no-repeat" /* Do not repeat the image */,
        backgroundSize:
            "cover" /* Resize the background image to cover the entire container */
    },
    logo: {
        width: "33.75rem",
        paddingTop: "9.375rem",
        paddingLeft: "10.625rem"
    },
    loginButtonHeight: "2.5rem",
    loginButtonWidth: "17.5rem",
    forgetPasswordStyle: {
        paddingTop: "0.625rem",
        color: mapping["Color_Basic_Primary"],
        textDecoration: "underline"
    },
    tenantDropDownStyle: {
        width: "17.5rem",
        height: "2.5rem",
        backgroundColor: mapping["Color_Basic_Secondary"],
        border: "none",
        boxShadow: "none"
    },
    fieldStyle: {
        width: "17.5rem",
        backgroundColor: "rgba(151,151,151,0.3)",
        color: "white"
    },
    logoSeparatorLogin: {
        display: "inline-flex",
        width: "16.25rem"
    },
    //---------------------resetPassword.js----------------------------------//

    InputFieldStyle: {
        height: mapping["Field_Height"],
        borderWidth: "0px",
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        border: "none !important",
        color: mapping["Color_Basic_Light"],
        padding: "0.25rem",
        resize: "none",
        overflow: "hidden",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        paddingLeft: mapping["Field_padding_Horizontal"],
        paddingRight: mapping["Field_padding_Horizontal"],
        borderRadius: 0,
        outline: "none",
        width: "100%"
    },
    popupFormBgStyle: {
        height: "fit-content",
        width: "28.125rem",
        padding: "1.4375rem 1.125rem 2.6875rem 1.125rem",
        backgroundColor: mapping["Color_Extra_Half_Transparent"],
        //display: "inline-flex",
        position: "relative"
    },
    RiskCreationFormStyle: {
        position: "relative",
        left: "-50%"
    },
    RiskCreationFormWrapStyle: {
        position: "absolute",
        left: "50%",
        top: "8rem"
    },
    fieldPadding: {
        height: "1rem"
    },
    popupButtonHeight: "2.4375rem",
    popupCancelButtonWidth: "4.9375rem",
    popupResetButtonWidth: "12.3125rem",
    popupDropDownStyle: {
        width: "100%",
        height: "2.5rem",
        backgroundColor: mapping["Color_Basic_Secondary"],
        border: "none",
        boxShadow: "none"
    }
};

export { loginStyle };
