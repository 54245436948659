import React from "react";

class RoleSwitchPanel extends React.Component {
    render() {
        const switchButtonStyle = {
            margin: "5px",
            padding: "5px",
            color: "white",
            textAlign: "center"
        };
        const RightsIndicatorStyle = {
            margin: "5px",
            width: "30px",
            color: "white",
            textAlign: "center"
        };
        console.log(this.props);
        return (
            <div style={{ position: "fixed", bottom: 0, left: 0 }}>
                <div style={{ display: "inline-flex" }}>
                    <div style={switchButtonStyle}>
                        {this.props.display_name}
                    </div>
                    <div style={switchButtonStyle}>{this.props.title}</div>
                </div>
                <div>
                    <div style={{ display: "inline-flex" }}>
                        <div
                            style={{
                                backgroundColor: this.props.rights.create
                                    ? "green"
                                    : "red",
                                ...RightsIndicatorStyle
                            }}
                        >
                            C
                        </div>
                        <div
                            style={{
                                backgroundColor: this.props.rights.edit
                                    ? "green"
                                    : "red",
                                ...RightsIndicatorStyle
                            }}
                        >
                            U
                        </div>
                        <div
                            style={{
                                backgroundColor: this.props.rights.view
                                    ? "green"
                                    : "red",
                                ...RightsIndicatorStyle
                            }}
                        >
                            R
                        </div>
                        <div
                            style={{
                                backgroundColor: this.props.rights.delete
                                    ? "green"
                                    : "red",
                                ...RightsIndicatorStyle
                            }}
                        >
                            D
                        </div>
                        <div
                            style={{
                                backgroundColor: this.props.rights.approve
                                    ? "green"
                                    : "red",
                                ...RightsIndicatorStyle
                            }}
                        >
                            A
                        </div>
                        <div
                            style={{
                                backgroundColor: this.props.rights.delegate
                                    ? "green"
                                    : "red",
                                ...RightsIndicatorStyle
                            }}
                        >
                            Dgt
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { RoleSwitchPanel };
