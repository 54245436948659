import moment from "moment";
import { requestToken } from "@aim-mf/api";
import { navigateToUrl } from "single-spa";

const DarkenColor = (Color, DarkPercent) => {
    var blockColor = Color;
    var R = Math.round(
        parseInt(blockColor.substring(1, 3), 16) * DarkPercent
    ).toString(16);
    if (R.length < 2) R = "0" + R;

    var G = Math.round(
        parseInt(blockColor.substring(3, 5), 16) * DarkPercent
    ).toString(16);
    if (G.length < 2) G = "0" + G;

    var B = Math.round(
        parseInt(blockColor.substring(5, 7), 16) * DarkPercent
    ).toString(16);
    if (B.length < 2) B = "0" + B;

    var resultColor = "#" + R + G + B;
    return resultColor;
};

const FormatDate = (date, format) => {
    return moment(date).format(format);
};

const CalculateDate = (date, year, month, days) => {
    let momentDate = moment(date);
    momentDate.add(days, "days");
    momentDate.add(month, "months");
    momentDate.add(year, "years");
    return momentDate;
};

const SortByProperty = (data, property, direction) => {
    if (direction === "asc") {
        data.sort((a, b) => {
            return a[property] > b[property] ? 1 : -1;
        });
    } else if (direction === "desc") {
        data.sort((a, b) => {
            return a[property] < b[property] ? 1 : -1;
        });
    } else {
        console.log(
            "direction error, only accept 'asc' or 'desc' but get " + direction
        );
    }
    return data;
};

const AuthCheckAndAutofix = (err, callbackFunction) => {
    // var name = 'aim_auth';
    // console.log("document cookies")
    // console.log(document.cookie)
    // let cookiesExist = new RegExp('^(.*;)?\\s*' + name + '\\s*=').test(document.cookie);
    // console.log(cookiesExist)
    // if (cookiesExist){
    //     navigateToUrl(baseURL+"login");
    // }
    let error_message = err.response.data.detail;

    if (err.request.responseType === "arraybuffer") {
        let decodedString = String.fromCharCode.apply(
            null,
            new Uint8Array(err.response.data)
        );
        console.log(decodedString);
        let obj = JSON.parse(decodedString);
        error_message = obj["detail"];
    }

    if (
        error_message === "Token expired" ||
        error_message === "Could not validate credentials"
    ) {
        let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
        let data = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: callbackFunction
        };
        console.log(data);
        requestToken(data);
    } else if (err.response.data.detail === "Not authenticated") {
        navigateToUrl("/login");
    }
    // else if (err.response.statusText === "Unauthorized") {
    //     let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    //     let data = {
    //         ou: currentUser.current_ou,
    //         tenant: currentUser.tenant,
    //         callbackFunction: callbackFunction
    //     };
    //     requestToken(data);
    // }
};

const JSDateTimeToDBDateTime = date => {
    var pad = function(num) {
        return ("00" + num).slice(-2);
    };
    let resultDate =
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        "Z";
    return resultDate;
};

const FindDisplayName = (userList, username) => {
    return userList.filter(user => {
        return user.username === username;
    })[0].display_name;
};

const LimitLength = (contents, separator, wordLength) => {
    if (contents.split(" ").length < wordLength) return contents;
    let result = contents.split(separator)[0];
    if (result.split(" ").length < wordLength) return result + " ...";
    if (result.split(" ").length > wordLength)
        return result.split(" ").splice(0, wordLength) + " ...";
};

// const getPlainText = (riskText)=>{
//     const reg = /<.+?>/gi;
//     return riskText.replace(reg, "");
// }

export {
    DarkenColor,
    FormatDate,
    SortByProperty,
    AuthCheckAndAutofix,
    JSDateTimeToDBDateTime,
    CalculateDate,
    FindDisplayName,
    LimitLength
};
