import React from "react";
import { CustomizedAutoComplete } from "./AutoComplete";
import { CustomDatePicker } from "./DatePicker";
import { FormatDate } from "./util";
import { NonValidationInput, NonValidationTextInput } from "./Field";
import { IconButtonGhost, IconButtonSolid, ButtonSolid } from "./button/Button";
import { DropDownListButton } from "./DropDownList";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { mapping } from "../style/mapping";
import { IconSVG } from "./Icon";
import { DragAndDrop } from "./DragAndDrop";
// test data you can put here, but i think for this no need test data

class TaskForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // put all your data here, and update states when there is a change
            inputTargetUser: "",
            inputDueDate: "",
            inputTitle: "",
            inputTaskDescription: "",
            actions: [],
            actionsContent: [],
            eventDeleteAction: "", // check if there is a change in action due to deletion or actual change
            uploadingFile: [],
            taskUploadingFiles: []
        };
    }
    componentWillReceiveProps(nextProps) {
        //this one you dun need to touch, it is a placeholder for integration with backend
    }
    render() {
        // the main page design is here
        let allowSubmit = true;

        if (this.state.inputTargetUser === "") allowSubmit = false;
        this.state.actions.forEach((type, index) => {
            console.log(this.state.actionsContent[index]);
            if (
                this.state.actionsContent[index].description === "" ||
                this.state.actionsContent[index].question === ""
            )
                allowSubmit = false;
            if (this.state.actionsContent[index].options) {
                this.state.actionsContent[index].options.forEach(option => {
                    if (option === "") allowSubmit = false;
                });
            }
        });
        if (this.state.inputTitle === "") allowSubmit = false;
        if (this.state.inputTaskDescription === "") allowSubmit = false;

        return (
            <div style={RiskCreationFormWrapStyle}>
                <div style={RiskCreationFormStyle}>
                    <div style={formBgStyle}>
                        <div
                            style={{
                                padding: "0rem",
                                height: "auto",
                                width: "36rem"
                            }}
                        >
                            <div
                                style={{
                                    padding: "1rem",
                                    marginBottom: "2rem"
                                }}
                            >
                                {/* TITLE **********************************************************************/}
                                <div style={FormTitleStyle}>
                                    Create New Task
                                </div>
                                {/*LINE 1 - TO */}
                                <div style={{ float: "left", width: "17rem" }}>
                                    <FieldTitle value={"To"} />
                                    <CustomizedAutoComplete
                                        style={{
                                            ...InputFieldStyle,
                                            height: "2rem",
                                            width: "15rem",
                                            padding: "0 0 0 0",
                                            margin: 0
                                        }}
                                        data={this.props.userList.map(
                                            a => a.display_name
                                        )}
                                        width={"16rem"}
                                        onCustomChange={this.onChangeTargetUser}
                                    />
                                </div>
                                {/*LINE 1 - DUE DATE*/}
                                <div style={{ float: "left", width: "17rem" }}>
                                    <FieldTitle value={"Due Date"} />
                                    <DatePicker
                                        value={new Date()}
                                        onChangeDueDate={this.onChangeDueDate}
                                    />
                                </div>
                                {/*LINE 2 - TITLE */}
                                <FieldTitle value={"Title"} />
                                <TextInput
                                    onCustomChange={this.onChangeTitle}
                                />
                                {/*LINE 3 - DESCRIPTION*/}
                                <FieldTitle value={"Description"} />
                                <MultiTextInput
                                    onCustomChange={
                                        this.onChangeTaskDescription
                                    }
                                />
                                <FieldTitle value={"Attachments"} />
                                <UploadBox
                                    ID={"createTaskID"}
                                    handleAttachmentChange={
                                        this.handleTaskUploadNewFiles
                                    }
                                    disabled={this.props.disabled}
                                    uploadingFile={
                                        this.state.taskUploadingFiles
                                    }
                                    handleAttachmentDownload={
                                        this.props.handleAttachmentDownload
                                    }
                                    handleRemoveUploadFile={
                                        this.handleRemoveUploadFile
                                    }
                                />
                            </div>

                            {/* NEW ACTION ****************************************************************/}
                            <div
                                style={{
                                    maxHeight: "30rem",
                                    overflowY: "scroll"
                                }}
                                className={"scrollbar"}
                            >
                                {this.state.actions.map((action, index) => {
                                    let actionsContent = this.state
                                        .actionsContent[index];
                                    if (action === "Select Form") {
                                        return (
                                            <ActionDefaultTemplate
                                                index={index}
                                                action={action}
                                                handleDeleteAction={
                                                    this.handleDeleteAction
                                                }
                                                handleSelectAction={
                                                    this.handleSelectAction
                                                }
                                            />
                                        );
                                    } else if (action === "Text Field") {
                                        return (
                                            <ActionTextInput
                                                index={index}
                                                action={action}
                                                actionsContent={actionsContent}
                                                handleDeleteAction={
                                                    this.handleDeleteAction
                                                }
                                                handleSelectAction={
                                                    this.handleSelectAction
                                                }
                                                onChangeTextField={
                                                    this.onChangeTextField
                                                }
                                            />
                                        );
                                    } else if (action === "Upload Request") {
                                        return (
                                            <ActionUploadRequest
                                                ID={index}
                                                index={index}
                                                action={action}
                                                actionsContent={actionsContent}
                                                uploadingFile={
                                                    this.state.uploadingFile
                                                }
                                                handleDeleteAction={
                                                    this.handleDeleteAction
                                                }
                                                handleSelectAction={
                                                    this.handleSelectAction
                                                }
                                                onChangeUploadRequest={
                                                    this.onChangeUploadRequest
                                                }
                                                handleAttachmentChange={
                                                    this.handleAttachmentChange
                                                }
                                                handleRemoveUploadFile={
                                                    this.handleRemoveUploadFile
                                                }
                                            />
                                        );
                                    } else if (action === "Multiple Choice") {
                                        return (
                                            <ActionMCQ
                                                index={index}
                                                action={action}
                                                actionsContent={actionsContent}
                                                handleDeleteAction={
                                                    this.handleDeleteAction
                                                }
                                                handleSelectAction={
                                                    this.handleSelectAction
                                                }
                                                handleRadioSelection={
                                                    this.handleRadioSelection
                                                }
                                                onChangeSurveyTitleInput={
                                                    this
                                                        .onChangeSurveyTitleInput
                                                }
                                                onChangeOptionInput={
                                                    this.onChangeOptionInput
                                                }
                                                handleDeleteOption={
                                                    this.handleDeleteOption
                                                }
                                                handleAddOption={
                                                    this.handleAddOption
                                                }
                                            />
                                        );
                                    }
                                })}
                            </div>
                            {/* ADD ACTION BUTTON *********************************************************/}
                            <div
                                style={{
                                    display: "flex",
                                    position: "relative",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingTop: "1rem",
                                    clear: "both"
                                }}
                            >
                                <IconButtonGhost
                                    fontStyle={
                                        mapping["Font_Button_Default_White"]
                                    }
                                    name={"Add Action"}
                                    height={"2.5rem"}
                                    width={"8rem"}
                                    color={"Primary"}
                                    iconPos={"Right"}
                                    icon={
                                        <IconSVG
                                            name={"newRisk"}
                                            color={"white"}
                                            size={"0.85"}
                                        />
                                    }
                                    clickEvent={() => {
                                        this.handleAddAction();
                                        // console.log("Add Action.");
                                    }}
                                />
                            </div>
                            {/* LAST LINE - CANCEL / SUBMIT ************************************************/}
                            <div
                                style={{
                                    // display: "flex",
                                    bottom: "0rem",
                                    width: "36rem",
                                    height: "3.5rem",
                                    marginBottom: "1rem",
                                    padding: "1rem"
                                }}
                            >
                                <div style={{ float: "left" }}>
                                    <ButtonSolid
                                        name={"Cancel"}
                                        height={"2.5rem"}
                                        width={"5rem"}
                                        color={"Secondary"}
                                        clickEvent={this.props.cancelFunction}
                                    />
                                </div>
                                <div style={{ float: "right" }}>
                                    <ButtonSolid
                                        name={"Submit"}
                                        height={"2.5rem"}
                                        width={"5rem"}
                                        color={"Primary"}
                                        disabled={!allowSubmit}
                                        clickEvent={this.handleSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    handleTaskUploadNewFiles = e => {
        let taskUploadingFiles = this.state.taskUploadingFiles;
        if (e.target) {
            let file = e.target.files[0];
            if (file) {
                taskUploadingFiles.push({
                    attachment_file: file,
                    name: file.name,
                    content_type: file.type
                });
            }
        } else {
            if (e[0] !== undefined) {
                //console.log(e[0]);
                let file = e[0];
                taskUploadingFiles.push({
                    attachment_file: file,
                    name: file.name,
                    content_type: file.type
                });
            }
        }
        this.setState({ taskUploadingFiles: taskUploadingFiles });
    };
    onChangeTargetUser = event => {
        let username = this.props.userList.filter(user => {
            return user.display_name === event.value;
        })[0];
        if (username !== undefined) {
            username = username.username;
        }
        this.setState({
            inputTargetUser: username
        });
    };
    onChangeDueDate = value => {
        this.setState({
            inputDueDate: FormatDate(value, "YYYY-MM-DD")
        });
    };
    onChangeTitle = event => {
        this.setState({
            inputTitle: event.value
        });
    };
    onChangeTaskDescription = event => {
        this.setState({
            inputTaskDescription: event.value
        });
    };
    handleAddAction = () => {
        let actions = this.state.actions;
        actions.push("Select Form"); //add 1 element to end of array
        this.setState({
            actions: actions
        });
        let actionsContent = this.state.actionsContent;
        actionsContent.push([]); //add 1 element to end of array
        this.setState({
            actionsContent: actionsContent
        });
    };
    handleDeleteAction = index => {
        let actions = this.state.actions;
        let actionsContent = this.state.actionsContent;
        actions.splice(index, 1);
        actionsContent.splice(index, 1);
        this.setState({
            actions: actions
        });
        this.setState({
            actionsContent: actionsContent
        });
        this.setState({
            eventDeleteAction: "deletion"
        });
    };
    handleSelectAction = (index, value) => {
        let actions = this.state.actions;
        let actionsContent = this.state.actionsContent;
        actions[index] = value;
        {
            if (this.state.eventDeleteAction !== "deletion") {
                console.log("Change in action. Reset content.");
                actionsContent[index] =
                    value === "Multiple Choice"
                        ? { type: "single", question: "", options: [] }
                        : { description: "" };
            } else {
                console.log("Change due to deletion. Do not reset content.");
            }
        }
        this.setState({
            actions: actions
        });
        this.setState({
            actionsContent: actionsContent
        });
        this.setState({
            eventDeleteAction: ""
        });
    };
    onChangeTextField = (index, value) => {
        let actionsContent = this.state.actionsContent;
        actionsContent[index].description = value;
        this.setState({
            actionsContent: actionsContent
        });
    };
    onChangeUploadRequest = (index, value) => {
        let actionsContent = this.state.actionsContent;
        actionsContent[index].description = value;
        this.setState({
            actionsContent: actionsContent
        });
    };
    handleRadioSelection = (index, value) => {
        console.log("radio change");
        let actionsContent = this.state.actionsContent;
        actionsContent[index].type = value;
        this.setState({
            actionsContent: actionsContent
        });
    };
    onChangeSurveyTitleInput = (index, value) => {
        let actionsContent = this.state.actionsContent;
        actionsContent[index].question = value;
        this.setState({
            actionsContent: actionsContent
        });
    };
    handleAddOption = index => {
        let actionsContent = this.state.actionsContent;
        actionsContent[index].options.push(""); //add 1 element to end of array
        this.setState({
            actionsContent: actionsContent
        });
    };
    onChangeOptionInput = (index, optionIndex, value) => {
        // console.log(value);
        let actionsContent = this.state.actionsContent;
        actionsContent[index].options[optionIndex] = value;
        this.setState({
            actionsContent: actionsContent
        });
    };
    handleDeleteOption = (index, optionIndex) => {
        let actionsContent = this.state.actionsContent;
        actionsContent[index].options.splice(optionIndex, 1);
        this.setState({
            actionsContent: actionsContent
        });
    };
    handleSubmit = () => {
        let taskTarget = [this.state.inputTargetUser];
        let actions = [];
        console.log(this.state.actionsContent);
        this.state.actions.forEach((type, index) => {
            actions.push({
                action_type: type,
                action_subtype: this.state.actionsContent[index].type,
                action_question:
                    this.state.actionsContent[index].description ||
                    this.state.actionsContent[index].question,
                action_answer: this.state.actionsContent[index][2],
                action_options: this.state.actionsContent[index].options,
                action_choices: [],
                attachments: []
            });
        });
        let taskContent = {
            to: taskTarget,
            due_date:
                this.state.inputDueDate || FormatDate(new Date(), "YYYY-MM-DD"),
            title: this.state.inputTitle,
            description: this.state.inputTaskDescription,
            actions: actions,
            attachments: this.state.taskUploadingFiles,
            task_from: this.props.currentUser,
            task_creator: this.props.currentUser
        };
        this.props.submitFunction(taskContent);
        console.log(taskContent);
    };
    handleAttachmentChange = e => {
        if (e.target) {
            let file = e.target.files[0];
            let taskUploadingFiles = this.state.taskUploadingFiles;
            if (file) {
                taskUploadingFiles.push(file);
            }
            this.setState({ taskUploadingFiles: taskUploadingFiles });
        } else {
            let taskUploadingFiles = this.state.taskUploadingFiles;
            if (e[0] !== undefined) {
                taskUploadingFiles.push(e[0]);
            }
            this.setState({ taskUploadingFiles: taskUploadingFiles });
        }
    };

    handleRemoveUploadFile = index => {
        console.log(index);
        let taskUploadingFiles = this.state.taskUploadingFiles;
        taskUploadingFiles.splice(index, 1);
        console.log(taskUploadingFiles);
        this.setState({ taskUploadingFiles: taskUploadingFiles });
    };
}

// all the style goes here
const formBgStyle = {
    height: "fit-content",
    width: "fit-content",
    backgroundColor: mapping["Color_Extra_Half_Transparent"],
    display: "inline-flex",
    position: "relative"
};
const FormTitleStyle = {
    ...mapping["heading/h5/lightleft"]
};
const RiskCreationFormStyle = {
    position: "relative",
    left: "-50%"
};
const RiskCreationFormWrapStyle = {
    position: "absolute",
    left: "50%",
    top: "13.3rem"
};
const FieldTitleStyle = {
    ...mapping["forms/label/1-default"]
};
const FieldTitle = props => {
    return (
        <div
            style={{
                marginTop: "1rem",
                float: "left",
                width: "16.5rem"
            }}
        >
            <div style={FieldTitleStyle}>{props.value}</div>
        </div>
    );
};
// online text input
const TextInput = props => {
    return (
        <NonValidationInput
            fieldstyle={{
                ...InputFieldStyle,
                height: props.height ? props.height : "2.25rem",
                width: props.width ? props.width : "34rem"
            }}
            value={props.value}
            onChange={props.onCustomChange}
        />
    );
};
const MultiTextInput = props => {
    return (
        <NonValidationTextInput
            placeholder={props.placeholder}
            rows={2}
            changerow={() => {}}
            minrows={2}
            maxrows={5}
            lineheight={22}
            fieldstyle={{
                ...InputFieldStyle,
                height: "6.75rem"
            }}
            value={props.value}
            onChange={props.onCustomChange}
        />
    );
};
const MultiTextInputWhite = props => {
    return (
        <NonValidationTextInput
            placeholder={props.placeholder}
            rows={2}
            changerow={() => {}}
            minrows={2}
            maxrows={5}
            lineheight={22}
            fieldstyle={{
                ...InputFieldStyleWhite,
                height: "6.75rem"
            }}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
        />
    );
};
const InputFieldStyle = {
    height: "2.25rem",
    width: "34rem",
    borderWidth: "0",
    backgroundColor: mapping["Color_Form_00_Field_Solid"],
    border: "none !important",
    color: mapping["Color_Basic_Light"],
    padding: "0.25rem",
    resize: "none",
    overflow: "hidden",
    paddingLeft: mapping["Field_padding_Horizontal"],
    paddingRight: mapping["Field_padding_Horizontal"],
    borderRadius: 0,
    outline: "none"
};
const InputFieldStyleWhite = {
    height: "2.25rem",
    width: "100%",
    borderWidth: "0",
    backgroundColor: mapping["Color_Form_00_Field_Solid"],
    border: "none !important",
    color: "white",
    padding: "0.25rem",
    resize: "none",
    overflow: "hidden",
    paddingLeft: mapping["Field_padding_Horizontal"],
    paddingRight: mapping["Field_padding_Horizontal"],
    borderRadius: 0,
    outline: "none"
};
const DatePicker = props => {
    return (
        <CustomDatePicker
            index={props.index}
            CustomOnChange={props.onChangeDueDate}
            value={FormatDate(props.value, "MM/DD/YYYY")}
        />
    );
};
const ActionStyle = {
    backgroundColor: "Black",
    padding: "1rem",
    height: "4.5rem",
    marginBottom: "1.5rem"
};
const NewAction = props => {
    const defaultDropdown = ["Text Field", "Upload Request", "Multiple Choice"];
    return (
        <div style={{ height: "2.25rem", paddingBottom: "2rem" }}>
            <div style={{ float: "left" }}>
                <DropDownListButton
                    value={props.selectedAction}
                    data={props.data ? props.data : defaultDropdown}
                    onChange={props.handleSelectAction}
                    disabled={props.disabled}
                />
            </div>
            <div style={{ float: "right" }}>
                <button
                    onClick={() => {
                        props.handleDeleteAction(props.actionIndex);
                    }}
                    style={{ cursor: "pointer", color: "white" }}
                    disabled={props.disabled}
                >
                    ✖
                </button>
            </div>
        </div>
    );
};
const TextFieldForm = props => {
    return (
        <div style={{ height: "6.75rem", marginTop: "0.4375rem" }}>
            <MultiTextInputWhite
                placeholder={"Write description"}
                value={props.value}
                onChange={props.onCustomChange}
                disabled={props.disabled}
            />
        </div>
    );
};

const UploadRequestForm = props => {
    return (
        <div
            style={{
                height: "fit-content",
                minHeight: "2.5rem",
                marginTop: "1rem"
            }}
        >
            <input
                className={"k-textbox"}
                placeholder={"Write description"}
                style={Object.assign(
                    {},
                    mapping["forms/input-default"],
                    InputFieldStyleWhite
                )}
                onChange={props.onCustomChange}
                value={props.value}
                disabled={props.disabled}
            />

            <UploadBox
                ID={props.ID}
                handleAttachmentChange={props.handleAttachmentChange}
                disabled={props.disabled}
                uploadingFile={props.uploadingFile}
                handleAttachmentDownload={props.handleAttachmentDownload}
                handleRemoveUploadFile={props.handleRemoveUploadFile}
            />
        </div>
    );
};

const UploadBox = props => {
    const noPermission = () => {
        console.log("you have no permission to upload");
    };
    return (
        <div>
            <input
                id={"contained-button-file" + props.ID}
                multiple
                type="file"
                style={{ display: "none" }}
                onChange={props.handleAttachmentChange}
                disabled={props.disabled}
            />
            <label
                style={{ width: "100%" }}
                htmlFor={"contained-button-file" + props.ID}
            >
                <DragAndDrop
                    empty={props.uploadingFile.length === 0}
                    handleDrop={
                        props.disabled
                            ? noPermission
                            : props.handleAttachmentChange
                    }
                >
                    <div>
                        {props.uploadingFile.map((attachment, index) => {
                            if (!attachment) {
                                return <div />;
                            }
                            return (
                                <div key={index}>
                                    <div style={AttachmentWrapper}>
                                        <IconSVG
                                            name={"file"}
                                            color={mapping["Color_Basic_Link"]}
                                        />
                                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                        <div
                                            style={AttachmentStyle}
                                            onClick={event => {
                                                event.preventDefault();
                                                props.handleAttachmentDownload(
                                                    attachment.id,
                                                    attachment.name
                                                );
                                            }}
                                        >
                                            {attachment.name}
                                        </div>
                                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                        <div
                                            className={
                                                props.disabled
                                                    ? ""
                                                    : "div_hover"
                                            }
                                            style={{
                                                ...AttachmentDeleteButtonWrapperStyle,
                                                backgroundColor: props.disabled
                                                    ? "grey"
                                                    : mapping["Color_Basic_Primary"] //eslint-disable-line
                                            }}
                                            onClick={event => {
                                                event.preventDefault();
                                                if (!props.disabled) {
                                                    props.handleRemoveUploadFile(
                                                        index
                                                    );
                                                }
                                            }}
                                        >
                                            <IconSVG
                                                name={"delete"}
                                                size={"0.8"}
                                                color={"#eeeeee"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </DragAndDrop>
            </label>
        </div>
    );
};

const MultipleChoiceHeader = props => {
    return (
        <div style={{ height: "auto", marginBottom: "1rem" }}>
            <div style={{ marginTop: "1rem" }}>
                <RadioButtonGroup
                    // value={props.radioTitle} // this is the default value of dropdown
                    // value={"multi"}
                    retrieveValue={props.radioTitle}
                    data={props.radioData} // these are the options of the dropdown, should be in the format I give below
                    CustomOnChange={props.onRadioCustomChange}
                />
            </div>
            <div style={{ marginTop: "1rem" }}>
                <input
                    placeholder={"Survey title"}
                    value={props.inputValue}
                    style={Object.assign(
                        {},
                        mapping["forms/input-default"],
                        InputFieldStyleWhite
                    )}
                    onChange={props.onInputCustomChange}
                />
            </div>
        </div>
    );
};
const AddedOption = props => {
    return (
        <div
            style={{
                backgroundColor: mapping["Color_Form_00_Field_Solid"],
                width: "34rem",
                height: "2.25rem",
                marginTop: "0.5rem",
                alignContent: "center"
            }}
        >
            <div style={{ float: "left" }}>
                <TextInput
                    value={props.option}
                    width={"31.5rem"}
                    height={"2.25rem"}
                    onCustomChange={props.onChangeOptionInput}
                />
            </div>
            <div
                style={{
                    float: "right",
                    marginRight: "0.25rem",
                    marginTop: "0.5625rem",
                    width: "1.5rem"
                }}
            >
                <button
                    onClick={props.handleDeleteOption}
                    style={{
                        ...FieldTitleStyle,
                        cursor: "pointer",
                        color: "white"
                    }}
                >
                    ✖
                </button>
            </div>
        </div>
    );
};
const radioGroupData = [
    { label: "Single Answer", value: "single", className: "task blue" },
    { label: "Multiple Answers", value: "multi", className: "task blue" }
];

class RadioButtonGroup extends React.Component {
    constructor() {
        super();
        this.state = {};
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.retrieveValue !== this.props.retrieveValue) {
            this.setState({ value: nextProps.retrieveValue });
            this.props.CustomOnChange({ value: nextProps.retrieveValue });
        }
    }
    render() {
        const defaultFontStyle = Object.assign(
            {},
            mapping["forms/label/1-default"],
            { color: "white" }
        );
        return (
            <div>
                <RadioGroup
                    defaultValue={this.props.data[0].value}
                    style={defaultFontStyle}
                    data={this.props.data}
                    layout={"horizontal"}
                    onChange={event => {
                        this.setState({ value: event.value });
                        this.props.CustomOnChange(event);
                    }}
                    value={this.state.value}
                />
                <style>
                    {`.k-radio.task { background: white; border-color: grey; box-shadow: none; }
                .k-radio.blue { background-color:rgba(255,255,255,0.11); border:solid 0.0625rem #00BEFF; }
                .k-radio.blue:checked { background-color:#00BEFF; color: white; }`}
                </style>
            </div>
        );
    }
}

const ActionDefaultTemplate = props => {
    const handleDeleteAction = () => {
        props.handleDeleteAction(props.index);
    };
    const handleSelectAction = event => {
        props.handleSelectAction(props.index, event.value);
    };
    return (
        <div style={ActionStyle}>
            <NewAction
                data={props.data}
                actionIndex={props.index}
                selectedAction={props.action}
                handleDeleteAction={handleDeleteAction}
                handleSelectAction={handleSelectAction}
                disabled={props.disabled}
            />
        </div>
    );
};

const ActionTextInput = props => {
    const handleDeleteAction = () => {
        props.handleDeleteAction(props.index);
    };
    const handleSelectAction = event => {
        props.handleSelectAction(props.index, event.value);
    };
    const onChangeTextField = event => {
        props.onChangeTextField(props.index, event.value);
    };
    return (
        <div style={{ ...ActionStyle, height: "190" }}>
            <NewAction
                data={props.data}
                actionIndex={props.index}
                selectedAction={props.action}
                handleDeleteAction={handleDeleteAction}
                handleSelectAction={handleSelectAction}
                disabled={props.disabled}
            />
            <TextFieldForm
                value={props.actionsContent.description}
                disabled={props.disabled}
                onCustomChange={onChangeTextField}
            />
        </div>
    );
};

const ActionUploadRequest = props => {
    //console.log(props.ID)
    const handleDeleteAction = () => {
        props.handleDeleteAction(props.index);
    };
    const handleSelectAction = event => {
        props.handleSelectAction(props.index, event.value);
    };
    const onChangeUploadRequest = event => {
        props.onChangeUploadRequest(props.index, event.target.value);
    };
    return (
        <div
            style={{
                ...ActionStyle,
                height: "fit-content",
                minHeight: "190"
            }}
        >
            <NewAction
                data={props.data}
                actionIndex={props.index}
                selectedAction={props.action}
                handleDeleteAction={handleDeleteAction}
                handleSelectAction={handleSelectAction}
                disabled={props.disabled}
            />
            <UploadRequestForm
                ID={props.ID}
                value={props.actionsContent.description}
                uploadingFile={props.uploadingFile}
                handleAttachmentChange={props.handleAttachmentChange}
                handleRemoveUploadFile={props.handleRemoveUploadFile}
                handleAttachmentDownload={props.handleAttachmentDownload}
                onCustomChange={onChangeUploadRequest}
                disabled={props.disabled}
            />
        </div>
    );
};

const ActionMCQ = props => {
    const handleDeleteAction = () => {
        props.handleDeleteAction(props.index);
    };
    const handleSelectAction = event => {
        props.handleSelectAction(props.index, event.value);
    };
    const handleRadioSelection = event => {
        props.handleRadioSelection(props.index, event.value);
    };
    const onChangeSurveyTitleInput = event => {
        props.onChangeSurveyTitleInput(props.index, event.target.value);
    };
    let actionsContent = props.actionsContent;
    return (
        <div style={{ ...ActionStyle, height: "auto" }}>
            <NewAction
                data={props.data}
                actionIndex={props.index}
                selectedAction={props.action}
                handleDeleteAction={handleDeleteAction}
                handleSelectAction={handleSelectAction}
                disabled={props.disabled}
            />
            <MultipleChoiceHeader
                radioTitle={
                    actionsContent.type === "multi" ? "multi" : "single"
                }
                radioData={radioGroupData}
                onRadioCustomChange={handleRadioSelection}
                inputValue={actionsContent.question}
                onInputCustomChange={onChangeSurveyTitleInput}
            />
            {actionsContent.options.map((option, optionIndex) => {
                return (
                    <div>
                        <AddedOption
                            option={option}
                            onChangeOptionInput={event => {
                                props.onChangeOptionInput(
                                    props.index,
                                    optionIndex,
                                    event.value
                                );
                            }}
                            handleDeleteOption={() => {
                                props.handleDeleteOption(
                                    props.index,
                                    optionIndex
                                );
                            }}
                        />
                    </div>
                );
            })}
            <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                <IconButtonSolid
                    name={"Add choice"}
                    height={"2rem"}
                    width={"8rem"}
                    color={"Primary"}
                    iconPos={"Right"}
                    icon={
                        <IconSVG
                            name={"newRisk"}
                            color={"white"}
                            size={"0.8"}
                        />
                    }
                    clickEvent={() => {
                        props.handleAddOption(props.index);
                    }}
                />
            </div>
        </div>
    );
};
const AttachmentWrapper = {
    paddingLeft: "5%",
    marginTop: "0.3125rem",
    marginBottom: "0.3125rem",
    display: "inline-flex",
    alignItems: "center",
    height: "2.5rem",
    width: "90%",
    borderRadius: "1.5rem",
    backgroundColor: mapping["Color_BG_03_trans"]
};

const AttachmentStyle = {
    textAlign: "left",
    paddingLeft: "0.8125rem",
    height: "1.375rem",
    width: "85%",
    fontFamily: "Roboto",
    fontSize: "1rem",
    letterSpacing: "0.0125rem",
    lineHeight: "1.375rem",
    color: mapping["Color_Basic_Info"],
    textDecoration: "underline"
};

const AttachmentDeleteButtonWrapperStyle = {
    margin: "auto",
    marginRight: "1.4375rem",
    height: "1.5rem",
    width: "1.5rem",
    borderRadius: "0.19rem",
    backgroundColor: mapping["Color_Basic_Primary"]
};
export {
    TaskForm,
    ActionDefaultTemplate,
    ActionMCQ,
    ActionTextInput,
    ActionUploadRequest,
    UploadBox
};
