import React from "react";
import PropTypes from "prop-types";
import { CustomizedFieldInput } from "./TextField";

const iconStyle = {
    color: "white",
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem"
};

class SearchBar extends React.Component {
    render() {
        const { classes } = this.props;
        var hint = this.props.hint ?? "Search...";

        const searchBar = (
            <div style={{ position: "relative" }}>
                <span class="k-icon k-i-search" style={iconStyle} />
                <CustomizedFieldInput
                    backgroundColor={this.props.backgroundColor}
                    hint={hint}
                    onChange={this.props.searchCallBack}
                    height={"2rem"}
                    width={this.props.width ?? "20rem"}
                />
            </div>
        );

        return <div>{searchBar}</div>;
    }
}

export { SearchBar };
