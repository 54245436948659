import React from "react";
import { MultiSelector } from "./MultiSelector";
import { ButtonSolid } from "./button/Button";
// need props:
//------------------------Form-------------------
// UserList
// owner
//------------------------button-----------------
// height
// width
// size
// name
//------------------------others-----------------
// handleBlur (function to blur the background, should come form homepage)
class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: this.props.currentUser,
            selectedUser: []
        };
    }
    render() {
        console.log(this.state.currentUser.currentUser);
        let alluser = [];
        return (
            <div style={RiskCreationFormWrapStyle}>
                <div style={RiskCreationFormStyle}>
                    <div
                        style={{
                            width: "33.75rem",
                            height: "fit-content",
                            backgroundColor: "rgba(0,0,0,0.4)",
                            padding: "1.2rem"
                        }}
                    >
                        <MultiSelector
                            title={this.props.title}
                            userList={this.props.userList}
                            value={this.state.currentUser.currentUser}
                            CustomOnChange={this.CustomOnChange}
                        />
                        <div style={buttonWrapper}>
                            <ButtonSolid
                                name={"Cancel"}
                                height={"38px"}
                                width={"120px"}
                                size={"Small"}
                                clickEvent={this.props.handleAddUserClose}
                            />
                            <ButtonSolid
                                name={"Add"}
                                height={"38px"}
                                width={"120px"}
                                size={"Small"}
                                clickEvent={() => {
                                    this.props.handleAddUserSubmit(
                                        this.state.selectedUser
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    CustomOnChange = event => {
        console.log(event.value);
        let IDarray = event.value;
        let userSelection = [];
        IDarray.forEach((username, index) => {
            let ownerDetail = this.props.userList.filter(user => {
                return user.username === username;
            });
            userSelection.push(ownerDetail[0]);
        });
        this.setState({ selectedUser: userSelection });
    };
}
const RiskCreationFormStyle = {
    position: "relative",
    left: "-50%"
};
const RiskCreationFormWrapStyle = {
    position: "fixed",
    left: "50%",
    top: "13.3rem"
};

const buttonWrapper = {
    display: "inline-flex",
    paddingTop: "31px",
    width: "100%",
    justifyContent: "space-between"
};
export { AddUser };
