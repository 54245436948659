import React from "react";

class TooltipTemplate extends React.Component {
    render() {
        return (
            <div style={this.props.tooltipStyle}> {this.props.content} </div>
        );
    }
}
export { TooltipTemplate };
