import React from "react";
import { mapping } from "../style/mapping";

class RiskEditCardHeader extends React.Component {
    render() {
        return (
            <header>
                <div style={mainHeader}>{this.props.title}</div>
                {/* TOP ROW HEADER - RIGHT BUTTONS -----------------------------------*/}
                <div style={{ float: "right" }}>
                    {/* --- LAST EDIT */}
                    <div style={lastEdit}>
                        Last edited by {this.props.lastEditName}
                    </div>
                    {/* --- CREATE TASK BUTTON */}
                    <button
                        onClick={() => {
                            // eslint-disable-next-line no-console
                            this.props.handleCreateTask();
                        }}
                        style={createtaskButton}
                    >
                        Create Task
                    </button>
                    {/* --- EDIT BUTTON */}
                    <button
                        onClick={() => {
                            // eslint-disable-next-line no-console
                            console.log("Edit clicked.");
                        }}
                        style={editButton}
                    >
                        Edit
                    </button>
                </div>
            </header>
        );
    }
}

// STYLES

var lastEdit = {
    display: "inline-flex",
    marginRight: "1.25rem",
    color: "rgb(149, 155, 167)",
    fontSize: "0.75rem",
    fontFamily: "corbel",
    fontWeight: "bold"
};

var createtaskButton = {
    paddingTop: "0.3125rem",
    display: "inline-flex",
    marginRight: "0.125rem",
    alignSelf: "center",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    height: "2.1875rem",
    backgroundColor: mapping["Color_Basic_Mute_2"],
    textAlign: "center",
    color: "white",
    fontSize: "0.9375rem",
    fontFamily: "corbel",
    fontWeight: "bold",
    transition: "0.3s"
    //onMouseEnter: { background: "blue" }, // doesn't work
};

var editButton = {
    paddingTop: "0.3125rem",
    display: "inline-flex",
    height: "2.1875rem",
    alignSelf: "center",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    backgroundColor: mapping["Color_Basic_Mute_2"],
    textAlign: "center",
    color: "white",
    fontSize: "0.9375rem",
    fontFamily: "corbel",
    borderTopRightRadius: "0.5rem",
    fontWeight: "bold",
    transition: "0.3s"
    // outline
};
const mainHeader = {
    display: "inline-flex",
    paddingLeft: "1.0625rem",
    paddingTop: "1.111rem",
    color: "white",
    ...mapping["heading/h5/lightleft"]
};
export { RiskEditCardHeader };
