import { mapping } from "./mapping";

const dashboardStyle = {
    RiskTowerDivStyle: {
        display: "inline-flex",
        paddingRight: "1.25rem",
        width: "100%"
    },
    TitleFontStyle: {
        paddingLeft: "10.625rem",
        paddingTop: "2.5rem", //40px,  40/16
        paddingBottom: "1.25rem", //20px,  20/16
        ...mapping["heading/h3/lightleft"]
    },
    RiskCountFontStyle: {
        paddingLeft: "10.625rem",
        paddingBottom: "0.9375rem", //15px,    15/16
        ...mapping["heading/h5/lightleft"]
    },
    RiskCountAlignStyle: {
        paddingRight: "1.6875rem", //27px,   27/16
        display: "inline-flex"
    },
    pillWidth: {
        width: "8.125rem" //130px,    130/16
    },
    searchBarWidth: {
        width: "20rem"
    },
    typeFilterDropDownStyle: {
        width: "11.25rem"
    },
    RiskTowerStyle: {
        paddingLeft: "10.625rem",
        display: "inline-flex"
    },

    RowStyle: {
        display: "inline-flex",
        marginTop: "3.75rem"
        //marginLeft: "170px",
    },
    ColStyle: {
        marginBottom: "0.625rem"
    },
    ChartWrapperStyle: {
        position: "relative",
        height: "17.98rem",
        width: "18.75rem",
        backgroundColor: "#2F3B52",
        boxShadow: "0 0.125rem 0.6875rem 0 rgba(0,0,0,0.3)",
        marginRight: "1.25rem",
        borderRadius: "0.375rem"
    },
    sectionTitleStyle: {
        ...mapping["Sub Text Tag/H7-blue-tag"],
        height: "2.625rem",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#00BEFF"
    },
    topBarFootnoteFontStyle: {
        margin: "auto",
        height: "0.875rem",
        width: "fit-content",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "0.66rem",
        letterSpacing: "0",
        lineHeight: "0.875rem"
    },
    topBarContentStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "inherit",
        margin: "auto",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "0.7875rem",
        fontWeight: "bold",
        letterSpacing: "0"
    },
    footNoteStyle: {
        margin: "auto",
        height: "0.875rem",
        width: "fit-content",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "0.66rem",
        letterSpacing: "0",
        lineHeight: "0.875rem",
        position: "absolute",
        bottom: "1px",
        right: "0.3125rem"
    },
    ChartArrayWrapperStyle: {
        display: "inline-flex",
        marginTop: "3.75rem",
        marginLeft: "10.625rem"
    },
    progressBarLabelStyle: {
        display: "inline-flex",
        marginLeft: "0.625rem",
        width: "16.44rem"
    },
    progressBarNonDataPillStyle: {
        backgroundColor: "#4E5C75",
        height: "1.64rem",
        width: "87%"
    },
    progressBarDataPillStyle: {
        margin: "auto",
        width: "16.44rem"
    }
};

const dashboardChartProgressBarStyle = (direction, width) => {
    if (direction === "left") {
        return {
            ...dashboardChartStyle.topBarContentStyle,
            display: "inline-flex",
            height: "1.64rem",
            width: width + "rem",
            borderRadius: "105px 0 0 105px",
            backgroundColor: "#007ADC"
        };
    } else {
        return {
            ...dashboardChartStyle.topBarContentStyle,
            display: "inline-flex",
            height: "1.64rem",
            width: width + "rem",
            borderRadius: "0 105px 105px 0",
            backgroundColor: "#4E5C75"
        };
    }
};
export { dashboardStyle, dashboardChartProgressBarStyle };
