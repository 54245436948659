import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

const modulesQuill = {
    toolbar: {
        container: [
            //[{ header: [1, 2,false] }],
            //[{ font: [] }],
            //[{ align: [] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }],
            [{ list: "bullet" }],
            [
                {
                    color: [
                        "#000000",
                        "#e60000",
                        "#ff9900",
                        "#ffff00",
                        "#008a00",
                        "#0066cc",
                        "#9933ff",
                        "#ffffff",
                        "#facccc",
                        "#ffebcc",
                        "#ffffcc",
                        "#cce8cc",
                        "#cce0f5",
                        "#ebd6ff",
                        "#bbbbbb",
                        "#f06666",
                        "#ffc266",
                        "#ffff66",
                        "#66b966",
                        "#66a3e0",
                        "#c285ff",
                        "#888888",
                        "#a10000",
                        "#b26b00",
                        "#b2b200",
                        "#006100",
                        "#0047b2",
                        "#6b24b2",
                        "#444444",
                        "#5c0000",
                        "#663d00",
                        "#666600",
                        "#003700",
                        "#002966",
                        "#3d1466",
                        "custom-color"
                    ]
                }[{ background: [] }],
                ["link"]
            ]
        ],
        handlers: {
            color: function(value) {
                if (value === "custom-color")
                    value = window.prompt("Enter Hex Color Code");
                this.quill.format("color", value);
            }
        }
    }
    //keyboard: {
    //    bindings: {
    //        tab: false,
    //        custom: {
    //            key: 13,
    //            shiftKey: true,
    //            handler: function() {
    //                /** do nothing */
    //            }
    //        },
    //        handleEnter: {
    //            key: 13,
    //            handler: function() {
    //                /** do nothing */
    //            }
    //        }
    //    }
    //},
    //"emoji-toolbar": true,
    //"emoji-textarea": true,
    //"emoji-shortname": true
};

const formatsQuill = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "align",
    "link",
    "image",
    "background",
    "color",
    "emoji"
];

class RichTextEditor extends React.Component {
    render() {
        return (
            <ReactQuill
                className={"richTextEditor"}
                style={this.props.style}
                modules={modulesQuill}
                formats={formatsQuill}
                theme={"bubble"}
                onChange={this.props.onChange}
                value={this.props.value}
                readOnly={this.props.disabled}
                //modules={Editor.modules}
                //formats={Editor.formats}
                //bounds={'.app'}
                //placeholder={this.props.placeholder}/>;
            />
        );
    }
}

export { RichTextEditor };
