import React from "react";
import { mapping } from "../style/mapping";
import { SmallAvatar } from "./Avatar";
import { FindDisplayName } from "./util";

class NameCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const UserContentStyle = {
            display: "inline-flex",
            paddingBottom: "1.25rem",
            ...this.props.UserContentStyle
        };

        let displayName =
            this.props.name ??
            FindDisplayName(this.props.userList, this.props.username);
        let initial =
            this.props.initial ??
            displayName
                .split(" ")
                .map(a => {
                    return a[0].toUpperCase();
                })
                .join("");

        return (
            <div style={UserContentStyle}>
                <SmallAvatar
                    size={"2.5rem"}
                    initial={initial}
                    pic={this.props.pic}
                />
                <div style={UserTextWrapperStyle}>
                    <div
                        data-test={"display_name"}
                        style={{
                            ...UserNameStyle,
                            ...this.props.nameStyle
                        }}
                    >
                        {displayName}
                    </div>
                    <div
                        style={{
                            ...UserRoleStyle,
                            ...this.props.roleStyle
                        }}
                    >
                        {this.props.title}
                    </div>
                </div>
            </div>
        );
    }
}

const UserTextWrapperStyle = {
    display: "inline-block",
    marginLeft: "0.4rem"
};
const UserNameStyle = {
    ...mapping["paragraph/default/lightleft"],
    height: "1.25rem",
    paddingTop: "0.2rem"
};

const UserRoleStyle = {
    ...mapping["Font_Avatar_Role"],
    paddingTop: "0.2rem"
};

export { NameCard };
