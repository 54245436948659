import React from "react";
import { Checkbox } from "@progress/kendo-react-inputs";

class CustomizedCheckBox extends React.Component {
    render() {
        return (
            <div style={{ display: "flex", ...this.props.checkBoxDivStyle }}>
                <div
                    style={{
                        margin: "auto",
                        ...this.props.checkBoxWrapperStyle
                    }}
                >
                    <Checkbox
                        style={{
                            backgroundColor: this.props.value
                                ? "#00BEFF"
                                : "rgba(255,255,255,0.11)",
                            border: "0.0625rem solid #00BEFF",
                            borderRadius: "0.25rem",
                            width: "1rem",
                            height: "1rem",
                            ...this.props.checkBoxStyle
                        }}
                        checked={this.props.value}
                        onChange={this.props.onCheckChange}
                        disabled={this.props.disabled}
                        labelPlacement={
                            this.props.labelPlacement
                                ? this.props.labelPlacement
                                : "after"
                        }
                    >
                        <label
                            style={{
                                marginLeft: "0.5rem",
                                marginBottom: 0,
                                padding: 0,
                                lineHeight: "1.0625rem",
                                display: "inline-flex",
                                alignItems: "flex-start",
                                verticalAlign: "middle",
                                position: "relative",
                                cursor: "pointer"
                            }}
                        >
                            {this.props.label}
                        </label>
                    </Checkbox>
                </div>
            </div>
        );
    }
}

export { CustomizedCheckBox };
