import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

class CustomizedTable extends React.Component {
    render() {
        let fields = Object.keys(this.props.data);
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div style={this.props.tableDivWrapper}>
                <Grid
                    style={{ height: "fit-content" }}
                    data={this.props.data}
                    rowRender={this.rowRender}
                >
                    {fields.map(field => {
                        let title = field[0].toUpperCase() + field.splice(1);
                        return <Column field={field} title={title} />;
                    })}
                </Grid>
            </div>
        );
    }

    rowRender(trElement, props) {
        const parentRow = { backgroundColor: "#00000066" };
        const subRiskRow = { backgroundColor: "#00000000" };
        const trProps = {
            style: props.dataItem.isParentRisk ? parentRow : subRiskRow
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    }
}

export { CustomizedTable };
