import React from "react";
import { mapping } from "../style/mapping";
import { Avatar } from "@progress/kendo-react-layout";
import "@progress/kendo-theme-bootstrap/dist/all.css";

class SmallAvatar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pic: this.props.pic,
            initial: this.props.initial
        };
    }

    render() {
        let radius = this.props.size
            ? this.props.size
            : mapping["Small_Avatar_Radius"];

        let fontSize = this.props.size
            ? this.props.size
            : mapping["Small_Avatar_Radius"];

        if (fontSize.includes("rem")) {
            fontSize = parseFloat(fontSize.split("r")[0]) * 0.3 + "rem";
        } else if (fontSize.includes("px")) {
            fontSize = parseFloat(fontSize.split("p")[0]) * 0.3 + "px";
        }

        var paddingStyle = {
            display: "inline-flex"
        };

        var initialAvatarFix = {
            position: "relative"
        };

        var avatarStyle = {
            height: radius,
            width: radius
        };

        var imgStyle = {
            height: "100%",
            width: "100%"
        };

        if (this.state.pic) {
            return (
                <div style={paddingStyle}>
                    <Avatar style={avatarStyle} shape="circle" type="image">
                        <img
                            alt={this.state.pic}
                            style={imgStyle}
                            src={this.state.pic}
                        />
                    </Avatar>
                </div>
            );
        } else if (this.props.initial) {
            return (
                <div style={Object.assign({}, paddingStyle, initialAvatarFix)}>
                    <Avatar style={avatarStyle} shape="circle" type="initials">
                        <div style={{ fontSize: fontSize }}>
                            {this.props.initial}
                        </div>
                    </Avatar>
                </div>
            );
        } else {
            return (
                <div style={Object.assign({}, paddingStyle, initialAvatarFix)}>
                    <Avatar style={avatarStyle} shape="circle" type="initials">
                        <div style={{ fontSize: fontSize }}>D</div>
                    </Avatar>
                </div>
            );
        }
    }
}

class LargeAvatar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pic: this.props.pic,
            initial: this.props.initial
        };
    }
    render() {
        var paddingStyle = {
            display: "inline-flex"
        };
        var initialAvatarFix = {
            position: "relative"
        };
        var avatarStyle = {
            height: mapping["Large_Avatar_Radius"],
            width: mapping["Large_Avatar_Radius"]
        };

        var imgStyle = {
            height: "100%",
            width: "100%"
        };

        if (this.state.pic) {
            return (
                <div style={paddingStyle}>
                    <Avatar style={avatarStyle} type="image">
                        <img
                            alt={this.state.pic}
                            style={imgStyle}
                            src={this.state.pic}
                        />
                    </Avatar>
                </div>
            );
        } else if (this.state.initial) {
            return (
                <div style={Object.assign({}, paddingStyle, initialAvatarFix)}>
                    <Avatar style={avatarStyle} type="initials">
                        {this.state.initial}
                    </Avatar>
                </div>
            );
        } else {
            return (
                <div style={Object.assign({}, paddingStyle, initialAvatarFix)}>
                    <Avatar style={avatarStyle} type="initials">
                        <span>D</span>
                    </Avatar>
                </div>
            );
        }
    }
}

export { SmallAvatar, LargeAvatar };
