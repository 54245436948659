import { mapping } from "./mapping";

const sideMenuStyle = {
    SideBarStyle: {
        width: "15.6875rem",
        backgroundColor: "#131821"
    },
    titleCategoryFont: {
        height: "1.5rem",
        width: "12.69rem",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "1.125rem",
        fontWeight: 500,
        letterSpacing: "0.3px",
        lineHeight: "1.5rem",
        marginTop: "2.56rem",
        marginBottom: "2.15rem",
        marginLeft: "1.125rem"
    },
    subMenuFont: {
        ...mapping["paragraph/small/lightleft"],
        height: "2.625rem",
        display: "flex",
        alignItems: "center",
        marginLeft: "1.4rem"
    },
    parentMenuFont: {
        height: "2.625rem",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "0.8125rem",
        fontWeight: "bold",
        letterSpacing: 0,
        lineHeight: "1.25rem",
        display: "flex",
        alignItems: "center",
        marginLeft: "1rem"
    },
    MenuWrapper: {
        height: "2.625rem",
        backgroundColor: "#131821",
        borderBottom: "0.0625rem solid #232C3D"
    },
    MenuWrapperHover: {
        height: "2.625rem",
        backgroundColor: "#232C3D",
        borderBottom: "0.0625rem solid #232C3D"
    },
    indicatorBar: {
        position: "absolute",
        width: "0.375rem",
        height: "2.625rem",
        backgroundColor: mapping["Color_Basic_Primary"]
    }
};
export { sideMenuStyle };
